import {
  saveAs
} from 'file-saver'
// async library for API calls
import axios from 'axios'
export default {
  state: {
    // loading flag,
    loadingFlag: true,
    // mobile mode boolean
    mobileMode: false,
    explorePanel: {
      'Controls': {
        label: 'Controls',
        active: true
      }
    }
  },
  getters: {
    getExplorePanel: state => {
      return state.explorePanel
    },
    getLoadingFlag: state => {
      return state.loadingFlag
    },
    getMobileMode: state => {
      return state.mobileMode
    }
  },
  mutations: {
    setLoadingFlag (state, loadingFlag) {
      state.loadingFlag = loadingFlag
    },
    setMobileMode (state, mobileMode) {
      state.mobileMode = mobileMode
    },
    setExplorePanel (state, payload) {
      state.explorePanel[payload[0]].active = payload[1]
    }
  },
  actions: {
    downloadSampleData: async function (context, type) {
      const APIURL = 'https://optimus.kpfui.dev/api/v2/'
      const projectBucket = 'kpfui-scout-project-bucket'

      switch (type) {
        case 'data.csv':

          let modelData = await axios.post(APIURL + 'getModelData/', {
            'Bucket': projectBucket,
            'Key': 'projects/DemoComp/data.csv'
          })

          modelData = modelData.data.model

          let csvHeader = 'data:text/csv;charset=utf-8,'
          let csvContent = modelData.map(e => Object.values(e).join(',')).join('\n')
          let header = Object.keys(modelData[0]).join(',')
          const d = csvHeader + '\n' + header + '\n' + csvContent

          saveAs(d, 'data.csv')

          break

        case 'settings.json':

          const settingsJSON = await axios.post(APIURL + 'getModelByID/', {
            'Bucket': projectBucket,
            'Key': 'projects/DemoComp/' + type
          })

          let fileName = 'settings.json'

          let fileToSave = new Blob([settingsJSON.data.model], {
            type: 'application/json',
            name: fileName
          })

          saveAs(fileToSave, fileName)

          break
      }
    }
  }
}
